<template>
  <div v-if="confirmationPopup.isShown==true" class="dim myriad">
    <div class="popup">
      <div class="header">
        <div class="popup-title">
          {{confirmationPopup.title}}
        </div>
        <div class="popup-action">
          <font-awesome-icon :icon="'fa-solid fa-times'" @click="closePopup"/>
        </div>
      </div>
      <div class="content">
        {{confirmationPopup.content}}
      </div>
      <div class="action">
        <button class="btn bg-primary border-primary" @click="closePopup"><font-awesome-icon icon="fa-solid fa-times"/> No</button>
        <button class="btn bg-white primary border-primary" @click="submit"><font-awesome-icon icon="fa-solid fa-check"/> Yes</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState([
      'confirmationPopup'
    ])
  },
  methods: {
    closePopup () {
      this.confirmationPopup.isShown = false
    },
    submit () {
      this.closePopup()
      this.confirmationPopup.confirmHandler()
    }
  }
}
</script>

<style scoped>
  .dim{
    width: 100vw;
    height: 100vh;
    background: rgba(0.5, 0.5, 0.5, 0.7);
    position: fixed !important;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .popup{
    background-color: white;
    width: 300px;
    padding: 10px;
    border-radius: 5px;
  }
  .header{
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 10px;
  }
  .popup-title{
    font-weight: bold;
    width: 90%;
  }
  .popup-action{
    text-align: right;
    width: 10%;
  }
  .content{
    padding: 10px;
  }
  .action{
    padding: 8px;
    text-align: right;
  }
  .action>button{
    width: 100px;
  }
  .btn.bg-primary{
    margin-right: 8px;
  }
  .fa-times{
    cursor: pointer;
  }
  .fa-times:hover{
    color: gray;
  }
  .bg-primary:hover{
    background-color: lightseagreen;
  }
  .bg-danger:hover{
    background-color: salmon;
  }
</style>
