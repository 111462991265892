<template>
  <div>
    <Navigation/>
    <NoCategory v-if="(budgets===null || budgets===undefined || budgets.length===0) && isBudgetFetched===true"/>
    <div class="panel">
      <div class="title">Budgets</div>
      <div class="row">
        <div class="col align-left">
          <router-link v-if="sortMode==false" to="/budget/new">
            <button class="bg-primary white btn"><font-awesome-icon icon="fa-solid fa-plus"/> New budget</button>
          </router-link>
          <button v-if="sortMode==true" @click="cancelSort" class="bg-primary white btn"><font-awesome-icon icon="fa-solid fa-times"/> Cancel</button>
        </div>
        <div class="col align-right">
          <button class="bg-primary white btn" @click="sort">
            <div v-if="sortMode==false">
              <font-awesome-icon icon="fa-solid fa-bars"/> Sort budget
            </div>
            <div v-if="sortMode==true">
              <font-awesome-icon icon="fa-solid fa-check"/> Save
            </div>
          </button>
        </div>
      </div>
      <div v-if="budgets != null && budgets.length">
        <draggable
          :list="budgets"
          :disabled="!sortMode"
          @start="dragging = true"
          @end="dragging = false"
          handle=".handle"
          :move="moveBudget">
          <div v-for="budget in budgets" :key="budget.id" >
            <CategoryRow :budget="budget" :sort_mode="sortMode"/>
          </div>
        </draggable>
      </div>
      <p class="align-right myriad primary bold">Total: Rp {{format(total)}},-</p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Navigation from '@/components/Navigation'
import CategoryRow from '@/components/CategoryRow'
import NoCategory from '@/components/NoCategory'
import { mapActions } from 'vuex'
import draggable from 'vuedraggable'
import { fetchBudget } from '@/controllers/budgets'

export default {
  name: 'Report',
  data () {
    return {
      unsortedBudgets: [],
      isOrderChange: false,
      budgets: [],
      sortMode: false,
      isBudgetFetched: false,
      total: 0
    }
  },
  components: {
    Navigation,
    CategoryRow,
    draggable,
    NoCategory
  },
  methods: {
    ...mapActions([
      'setSuccessAlert',
      'setErrorAlert',
      'setLoading',
      'finishLoading'
    ]),
    format (cost) {
      var numeral = require('numeral')
      return numeral(cost).format('0,0').replaceAll(',', '.')
    },
    async fetchBudgets () {
      this.setLoading()
      try {
        this.budgets = await fetchBudget()
      } catch (err) {
        this.setErrorAlert(err.message)
      }
      this.finishLoading()

      this.total = 0
      for (let i = 0; i < this.budgets.length; i++) {
        this.total += this.budgets[i].amount
      }
    },
    cancelSort () {
      this.budgets = this.unsortedBudgets
      this.sortMode = false
    },
    saveSort () {
      if (this.isOrderChange === false) {
        this.sortMode = false
        return
      }

      this.setLoading()
      let statusCode = 0

      const sortOrders = []
      for (let i = 1; i <= this.budgets.length; i++) {
        const sortOrder = {
          sort_order: i,
          budget_id: this.budgets[i - 1].id
        }
        sortOrders.push(sortOrder)
      }

      fetch(process.env.VUE_APP_BASE_URL + '/v1/budget/sort', {
        method: 'post',
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + localStorage.getItem('token') },
        body: JSON.stringify({ sort_orders: sortOrders })
      }).then((response) => {
        statusCode = response.status
        if (statusCode !== 200 && statusCode !== 400 && statusCode !== 401) {
          this.setErrorAlert('Something went wrong, please try again later')
          return
        }
        return response.json()
      }).then((jsonData) => {
        this.finishLoading()
        if (jsonData === undefined) {
          return
        }
        if (statusCode !== 200) {
          this.setErrorAlert(jsonData.message)
          return
        }
        this.setSuccessAlert('Success')
        this.sortMode = false
      })
    },
    sort () {
      if (this.sortMode === false) {
        this.unsortedBudgets = JSON.parse(JSON.stringify(this.budgets))
        this.sortMode = true
        this.isOrderChange = false
        return
      }
      this.saveSort()
    },
    moveBudget (e) {
      if (e.draggedContext.index !== e.draggedContext.futureIndex) {
        this.isOrderChange = true
      }
    }
  },
  mounted: function () {
    this.fetchBudgets()
  }
}
</script>

<style scoped>
  .btn{
    margin-bottom: 8px;
  }
  .row{
    justify-content: left;
  }
  .title{
    width: 50%;
    text-align: left;
  }
  .col{
    width: 50%;
    padding-top: 10px;
    padding-bottom:10px;
  }
</style>
