<template>
  <div class="home">
    <ExpenseDrawer v-if="expenseDrawerVisibility"
      :budgets="budgets"
      :expense="expense"
      @expense-created="expenseEventHandler"
      @expense-updated="expenseEventHandler"
      @visibility-change="setExpenseDrawerVisibility"
      :mode="expenseDrawerMode"/>
    <Navigation/>
    <div v-if="(budgets== null || budgets==undefined || budgets.length ==0) && isBudgetsFetched==true">
      <NoCategory/>
    </div>
    <div v-else-if="budgets.length>=1">
      <div class="myriad">
        <ProfileCard
          :name="name"
          :budget="format(sumBudget.budget)"
          :remaining_budget="format(sumBudget.remaining)"
        />
      </div>
      <div class="panel myriad">
        <div class="section-header">
          <div class="section-title">
            <div class="primary bold">Budgets</div>
          </div>
          <div class="section-action">
            <div class="section-button">
              <router-link to="/budget/new">
                <IconButton :text="'Add Budget'" :icon="'fa-plus'"/>
              </router-link>
            </div>
          </div>
        </div>
        <ListCategory :budgets="budgets"/>
      </div>
      <div v-for="expenses, date in groupedExpenses" :key="date" class="panel myriad today-panel">
        <div class="section-header">
          <div class="section-title">
            <div class="primary bold">{{ formatDate(date) }}</div>
          </div>
          <div class="section-action">
            <div class="section-button">
              <b class="primary">{{ 'Rp '+format(sumTotal(expenses))+',-' }}</b>
            </div>
          </div>
        </div>
        <ExpenseRows
          :expenses="expenses"
          :showDate="false"
          @edit-expense-selected="showEditExpenseDrawer"
        />
      </div>
      <NoData v-if="(expenses === null || expenses.length<=0) && isExpenseFetched==true"/>
    </div>
    <div class="fab bg-primary pointer" @click="showAddExpenseDrawer()">
      <div class="white fab-content"><font-awesome-icon :icon="'fa-solid fa-plus'"/></div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Navigation from '@/components/Navigation'
import ExpenseDrawer from '@/components/ExpenseDrawer'
import ListCategory from '@/components/ListCategory'
import ExpenseRows from '@/components/ExpenseRows'
import ProfileCard from '@/components/ProfileCard'
import NoData from '@/components/NoData'
import NoCategory from '@/components/NoCategory'
import { mapActions } from 'vuex'
import moment from 'moment'
import IconButton from '@/components/IconButton'
import _ from 'lodash'
import { fetchBudget } from '@/controllers/budgets'

export default {
  name: 'Home',
  components: {
    Navigation,
    ExpenseDrawer,
    ListCategory,
    ExpenseRows,
    ProfileCard,
    NoData,
    NoCategory,
    IconButton
  },
  data () {
    return {
      isBudgetsFetched: false,
      isExpenseFetched: false,
      budgets: [],
      expenses: [],
      groupedExpenses: [],
      expenseDrawerVisibility: false,
      expenseDrawerMode: 'CREATE',
      expense: this.newExpense(),
      name: localStorage.getItem('profile_name')
    }
  },
  methods: {
    ...mapActions([
      'setSuccessAlert',
      'setErrorAlert',
      'setLoading',
      'finishLoading'
    ]),
    newExpense () {
      return {
        id: '',
        date: moment().format('YYYY-MM-DD'),
        name: '',
        cost: 0,
        budget_id: '',
        notes: ''
      }
    },
    format (cost) {
      var numeral = require('numeral')
      return numeral(cost).format('0,0').replaceAll(',', '.')
    },
    sumTotal (expenses) {
      let total = 0
      expenses.forEach(function (expense, index) {
        total += expense.cost
      })
      return total
    },
    async fetchCategories () {
      this.setLoading()
      try {
        this.budgets = await fetchBudget()
      } catch (err) {
        this.setErrorAlert(err.message)
      }
      this.finishLoading()
    },
    fetchExpenses () {
      this.setLoading()
      let statusCode = 0

      fetch(process.env.VUE_APP_BASE_URL + '/v1/expense?sort_by=date&sort_order=desc', {
        method: 'get',
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + localStorage.getItem('token') }
      }).then((response) => {
        this.isExpenseFetched = true
        statusCode = response.status
        if (statusCode !== 200 && statusCode !== 400 && statusCode !== 401) {
          this.setErrorAlert('Something went wrong, please try again later')
          return
        }
        return response.json()
      }).then((jsonData) => {
        this.finishLoading()
        if (jsonData === undefined) {
          return
        }
        if (statusCode !== 200) {
          this.setErrorAlert(jsonData.message)
          return
        }
        this.expenses = jsonData.expenses
        this.groupedExpenses = _.groupBy(this.expenses, 'date')
      })
    },
    setExpenseDrawerVisibility (flag) {
      this.expenseDrawerVisibility = flag
    },
    expenseEventHandler (expense) {
      this.fetchExpenses()
      this.fetchCategories()
    },
    showAddExpenseDrawer () {
      this.expenseDrawerMode = 'CREATE'
      this.expense = this.newExpense()
      this.setExpenseDrawerVisibility(true)
    },
    showEditExpenseDrawer (expense) {
      this.expenseDrawerMode = 'UPDATE'
      this.expense = {
        ...expense
      }
      this.setExpenseDrawerVisibility(true)
    },
    formatDate (date) {
      return moment(date).format('dddd, D MMM YYYY')
    }
  },
  mounted: function () {
    this.fetchCategories()
    this.fetchExpenses()
  },
  computed: {
    sumBudget () {
      const total = {
        budget: 0,
        remaining: 0
      }
      this.budgets.forEach(function (budget, index) {
        total.budget += budget.amount
        total.remaining += (budget.amount - budget.used_amount)
      })
      return total
    }
  }
}
</script>

<style scoped>
  .section-header{
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  .section-action, .section-title{
    width: 50%;
  }
  .section-action{
    text-align: right !important;
  }
  .section-button{
    float: right;
  }
  a{
    text-decoration: none !important;
  }
  .fab{
    position: fixed;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    bottom: 0;
    right: 0;
    margin-bottom: 10px;
    margin-right: 7%;
    transition: transform 0.2s ease-in-out;
  }
  .fab-content {
    font-size: 23px;
    line-height: 52px; /* Center vertically */
    text-align: center; /* Center horizontally */
    display: block;
  }
  @media screen and (max-width: 650px) {
    .fab{
      margin-right: 10px;
    }
  }
  @media screen and (min-width: 1440px) {
    .fab{
      margin-right: 20%;
    }
  }
</style>
