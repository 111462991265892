<template>
  <div class="dim expense-drawer-dim" @click="close">
      <div class="drawer" @click.stop>
        <div class="header">
          <div class="title">
            {{ mode === 'UPDATE' ? 'Edit' : 'Create' }} Expense
          </div>
          <div class="action pointer expense-drawer-close-action">
            <font-awesome-icon :icon="'fa-solid fa-times'" @click="close"/>
          </div>
        </div>
        <DateField label="Date" :mandatory="true" v-model="expense.date"/>
        <TextField label="Expense" :mandatory="true" v-model="expense.name"/>
        <MoneyField label="Cost" :mandatory="true" v-model.number="expense.cost"/>
        <DropDownField label="Budget" :mandatory="true" v-model="expense.budget_id" :options="budgets" textName="name" placeholder="-- Select Budget --"/>
        <TextAreaField label="Notes" v-model="expense.notes"/>
        <button class="btn bg-primary" @click="submit">
          Save
        </button>
      </div>
  </div>
</template>

<script>
import MoneyField from '@/components/MoneyField'
import TextField from '@/components/TextField'
import TextAreaField from '@/components/TextAreaField'
import DropDownField from '@/components/DropDownField'
import DateField from '@/components/DateField'
import { mapActions } from 'vuex'
import moment from 'moment'

export default {
  props: {
    budgets: {
      type: Array,
      required: true
    },
    mode: {
      type: String,
      required: false,
      default: 'CREATE'
    },
    expense: {
      type: Object,
      required: false,
      default: () => {
        return {
          id: '',
          date: moment().format('YYYY-MM-DD'),
          name: '',
          cost: 0,
          budget_id: '',
          notes: ''
        }
      }
    }
  },
  watch: {
    expense (newExpense) {
      this.expense = newExpense
    },
    mode (newMode) {
      console.log(newMode)
      this.mode = newMode
    }
  },
  components: {
    DateField,
    DropDownField,
    TextField,
    TextAreaField,
    MoneyField
  },
  methods: {
    ...mapActions([
      'setSuccessAlert',
      'setErrorAlert',
      'setLoading',
      'finishLoading'
    ]),
    close () {
      this.$emit('visibility-change', false)
    },
    async submit () {
      if (this.expense.date === '') {
        this.setErrorAlert('Date is required')
        return
      } else if (this.expense.name === '') {
        this.setErrorAlert('Expense is required')
        return
      } else if (this.expense.cost < 0) {
        this.setErrorAlert('Cost is required')
        return
      } else if (this.expense.budget_id === '') {
        this.setErrorAlert('Budget is required')
        return
      }

      this.setLoading()
      if (this.mode === 'UPDATE') {
        if (this.expense.id === '') {
          this.setErrorAlert('Expense to be Edited Must be Selected')
          return
        }
        await this.editExpense()
      } else if (this.mode === 'CREATE') {
        await this.createExpense()
      }

      this.close()
      this.finishLoading()
    },
    async createExpense () {
      try {
        const response = await fetch(process.env.VUE_APP_BASE_URL + '/v1/expense', {
          method: 'post',
          headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + localStorage.getItem('token') },
          body: JSON.stringify(this.expense)
        })

        const statusCode = response.status
        if (statusCode !== 200 && statusCode !== 201 && statusCode !== 400 && statusCode !== 401) {
          throw new Error('Internal Server Error')
        }

        const jsonResponse = await response.json()

        if (statusCode !== 200 && statusCode !== 201) {
          this.setErrorAlert(jsonResponse.message)
          return
        }

        this.$emit('expense-created', this.expense)

        this.setSuccessAlert('Success!')
      } catch (err) {
        this.setErrorAlert('Something went wrong, please try again later')
      }
    },
    async editExpense () {
      try {
        const response = await fetch(process.env.VUE_APP_BASE_URL + '/v1/expense', {
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + localStorage.getItem('token') },
          body: JSON.stringify(this.expense)
        })

        const statusCode = response.status
        if (statusCode !== 200 && statusCode !== 201 && statusCode !== 400 && statusCode !== 401) {
          throw new Error('Internal Server Error')
        }

        const jsonResponse = await response.json()

        if (statusCode !== 200 && statusCode !== 201) {
          this.setErrorAlert(jsonResponse.message)
          return
        }

        this.$emit('expense-updated', this.expense)

        this.setSuccessAlert('Success!')
      } catch (err) {
        this.setErrorAlert('Something went wrong, please try again later')
      }
    }
  }
}
</script>

<style>
  .expense-drawer-dim{
    z-index: 99991 !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .drawer {
    width: 400px;
    height: 600px;
    padding: 25px;
    background-color: white;
    border-radius: 10px;
  }
  .drawer>.header{
    display: flex;
    justify-content: left;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .drawer .title{
    width: 95%;
    text-align: left;
  }
  .drawer .action{
    width: 5%;
    text-align: right;
  }
  @media screen and (max-width: 650px) {
    .drawer {
      width: 100vw;
      bottom: 0;
      right: calc(50% - 150px);
      border-radius: 10px 10px 0 0;
    }
    .expense-drawer-dim {
      align-items: end;
    }
  }
</style>
