<template>
  <div>
    <div class="label">{{ label }} <span v-if="mandatory" class="danger">*</span></div>
    <select :value="value" class="drop-down-field" @input="$emit('input', $event.target.value)">
      <option v-if="placeholder" value="">{{ placeholder }}</option>
      <option v-for="option in options" :key="option.id" :value="option.id" :selected="option.id === value">{{ option[textName] }}</option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true
    },
    textName: {
      type: String,
      required: true
    },
    mandatory: {
      type: Boolean,
      required: false
    },
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false
    },
    value: [String, Number]
  }
}
</script>

<style>
  .label{
    font-size: 14px;
    font-family: 'myriad';
    margin-bottom: 5px;
  }
  .drop-down-field{
    border:1px solid lightgray;
    border-radius:5px;
    padding: 10px;
    font-family: myriad;
    margin-bottom: 8px;
    width: 100%;
  }
</style>
