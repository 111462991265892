<template>
    <div class="sticky">
        <div class="nav">
        <ul>
            <li class="brand">
                <router-link to="/">ExpenHub</router-link>
            </li>
            <li>
                <router-link to="/">
                    <font-awesome-icon icon="fa-solid fa-home"/> Home
                </router-link>
            </li>
            <li>
                <router-link to="/report">
                    <font-awesome-icon icon="fa-solid fa-calendar-alt"/> Report
                </router-link>
            </li>
            <li>
                <router-link to="/budgets">
                    <font-awesome-icon icon="fa-solid fa-credit-card"/> Budgets
                </router-link>
            </li>
            <!-- <li>
                <router-link to="/statistic">
                    <i class="fa fa-chart-line"></i> Statistics
                </router-link>
            </li>
            <li>
                <router-link to="/wallet">
                    <i class="fa fa-wallet"></i> Wallet
                </router-link>
            </li> -->
            <!-- right side menu -->
            <li class="rightSideMenu" @click="signOut">
                <font-awesome-icon icon="fa-solid fa-sign-out-alt"/> Sign Out
            </li>
            <!-- <li class="rightSideMenu">
                <router-link to="/setting">
                    <i class="fa fa-cog"></i> Settings
                </router-link>
            </li> -->
        </ul>
        </div>
        <div class="nav-mobile-wrapper">
            <div class="nav-mobile">
                <font-awesome-icon icon="fa-solid fa-bars" class="pointer primary hamburger-menu" @click="toggleMenu"/> <span class="primary bold myriad"><router-link to="/">ExpenHub</router-link></span>
            </div>
            <div class="absolute">
                <div v-if="isMenuShown" class="mobile-menu-wrapper">
                    <router-link to="/"><div class="mobile-menu primary">
                        <font-awesome-icon icon="fa-solid fa-home"/> Home
                    </div></router-link>
                    <router-link to="/report"><div class="mobile-menu primary">
                        <font-awesome-icon icon="fa-solid fa-calendar-alt"/> Report
                    </div></router-link>
                    <router-link to="/budgets"><div class="mobile-menu primary">
                        <font-awesome-icon icon="fa-solid fa-credit-card"/> Budgets
                    </div></router-link>
                    <!-- <router-link to="/statistics"><div class="mobile-menu primary">
                        <i class="fa fa-chart-line"></i> Statistics
                    </div></router-link> -->
                    <!-- <router-link to="/wallet"><div class="mobile-menu primary">
                        <i class="fa fa-wallet"></i> Wallet
                    </div></router-link> -->
                    <!-- <router-link to="/setting"><div class="mobile-menu primary">
                        <i class="fa fa-cog"></i> Settings
                    </div></router-link> -->
                    <div class="mobile-menu primary" @click="signOut">
                        <font-awesome-icon icon="fa-solid fa-sign-out-alt"/> Sign Out
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data () {
    return {
      isMenuShown: false
    }
  },
  methods: {
    ...mapActions([
      'removeToken',
      'setSuccessAlert',
      'setErrorAlert',
      'setLoading',
      'finishLoading'
    ]),
    toggleMenu () {
      this.isMenuShown = !this.isMenuShown
    },
    signOut () {
      this.setLoading()

      fetch(process.env.VUE_APP_BASE_URL + '/v1/signout', {
        method: 'post',
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + localStorage.getItem('token') },
        body: JSON.stringify(this.user)
      }).then((response) => {
        this.finishLoading()
      })
      this.removeToken()
      this.$router.push({ name: 'Login' })
    }
  }
}
</script>

<style scoped>
    .hamburger-menu {
        float: right;
    }
    .sticky{
        position: sticky;
        top: 0;
    }
    .nav{
        width: calc(100% - 24px);
    }
    .nav-mobile-wrapper{
        display: none;
    }
    .nav,.nav-mobile{
        background-color: white;
        border-bottom: 1px solid lightgray;
        padding: 12px;
    }
    a{
        color: #009688 !important;
        text-decoration: none;
    }
    .nav ul{
        margin: 0;
        padding: 0;
    }
    .nav ul>li{
        display: inline-block;
        color: #009688;
        font-family: 'myriad';
        margin-right:20px !important;
        padding-top: 7px;
        padding-bottom: 7px;
        cursor: pointer;
    }
    .nav ul>.brand{
        font-weight: bold;
        padding-left: 8px;
        padding-right: 7px;
    }
    .rightSideMenu{
        float: right;
    }
    .mobile-menu-wrapper{
        background-color: white;
        padding-bottom: 15px;
        width: 100vw;
    }
    .mobile-menu{
        padding: 9px;
        font-family: 'myriad';
        cursor: pointer;
    }
    .mobile-menu>i{
        width: 23px;
        text-align: center;
    }

    @media screen and (max-width: 650px) {
        .nav{
            display: none;
        }
        .nav-mobile-wrapper{
            display: block;
        }
        .align-start{
            padding-bottom:50px;
        }
    }
</style>
