<template>
    <div class="login-page-wrapper bg-primary">
        <div class="login-form">
            <div class="brand primary">
              ExpenHub
            </div>
            <div class="remember-me-panel">
              <input class="checkbox" type="checkbox" name="rememberMe" v-model="user.remember_token">
              <span class="remember-me-label">Remember Me</span>
            </div>
            <div class="form-title secondary">Sign In With:</div>
            <LoginForm :signIn="signIn" :user="user"/>
        </div>
    </div>
</template>

<script>
import LoginForm from '@/components/LoginForm'
import { mapActions } from 'vuex'

export default {
  components: {
    LoginForm
  },
  data () {
    return {
      user: {
        name: '',
        email: '',
        id_token: '',
        remember_me: false,
        device: 'Web'
      }
    }
  },
  methods: {
    ...mapActions([
      'setSuccessAlert',
      'setErrorAlert',
      'setToken',
      'setLoading',
      'finishLoading'
    ]),
    signIn (googleUser) {
      this.setLoading()
      let statusCode = 0

      const profile = googleUser.getBasicProfile()
      this.user.name = profile.getName()
      this.user.email = profile.getEmail()
      this.user.id_token = googleUser.getAuthResponse().id_token
      this.user.device = 'User Agent = ' + navigator.userAgent

      fetch(process.env.VUE_APP_BASE_URL + '/v1/authentication', {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(this.user)
      }).then((response) => {
        statusCode = response.status
        if (statusCode !== 200 && statusCode !== 400 && statusCode !== 401) {
          this.setErrorAlert('Something went wrong, please try again later')
          return
        }
        return response.json()
      }).then((jsonData) => {
        this.finishLoading()
        if (jsonData === undefined) {
          return
        }
        if (statusCode !== 200) {
          this.setErrorAlert(jsonData.message)
          return
        }
        this.setToken({ key: jsonData.token, expiredAt: jsonData.expired_at })
        localStorage.setItem('profile_name', this.user.name)
        this.$router.push({ name: 'Home' })
      })
    }
  }
}
</script>

<style scoped>
  .login-form{
    background-color: white;
    width: 300px;
    padding: 20px;
  }
  .form-title{
    font-family: 'montserrat';
    margin-bottom: 8px;
    font-size: 15px;
  }
  .login-page-wrapper{
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: -999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .brand{
    font-weight: bold;
    font-family: 'myriad';
    font-size: 21px;
    margin-bottom: 10px;
  }

  .remember-me-panel{
    padding-top: 5px;
    padding-bottom: 10px;
  }
  .remember-me-label{
    font-family: 'montserrat';
    font-size: 13px;
    margin-left: 25px;
  }
  .checkbox{
    position: absolute;
  }
  .padded-label{
    padding:10px;
    font-size: 14px;
    font-family:'montserrat'
  }
</style>
