import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Report from '../views/Report.vue'
import Login from '../views/Login.vue'
import Categories from '../views/Categories.vue'
import Category from '../views/Category.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    props: true,
    beforeEnter: (to, from, next) => {
      if (store.getters.isAuthenticated === false) {
        return next({
          name: 'Login'
        })
      }
      next()
    }
  },
  {
    path: '/report',
    name: 'Report',
    component: Report,
    props: true,
    beforeEnter: (to, from, next) => {
      if (store.getters.isAuthenticated === false) {
        return next({
          name: 'Login'
        })
      }
      next()
    }
  },
  {
    path: '/budgets',
    name: 'Budgets',
    component: Categories,
    props: true,
    beforeEnter: (to, from, next) => {
      if (store.getters.isAuthenticated === false) {
        return next({
          name: 'Login'
        })
      }
      next()
    }
  },
  {
    path: '/budget/new',
    name: 'New Budget',
    component: Category,
    props: true,
    beforeEnter: (to, from, next) => {
      if (store.getters.isAuthenticated === false) {
        return next({
          name: 'Login'
        })
      }
      next()
    }
  },
  {
    path: '/budget/edit/:id',
    name: 'Edit Budget',
    component: Category,
    props: true,
    beforeEnter: (to, from, next) => {
      if (store.getters.isAuthenticated === false) {
        return next({
          name: 'Login'
        })
      }
      next()
    }
  },
  {
    path: '/signin',
    name: 'Login',
    component: Login,
    props: true,
    beforeEnter: (to, from, next) => {
      if (store.getters.isAuthenticated === true) {
        return next({
          name: 'Home'
        })
      }
      next()
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
