<template>
    <div class="myriad row category-row">
      <div v-if="sort_mode==true" class="handle">
        <font-awesome-icon icon="fa-solid fa-bars" />
      </div>
      <div class="col-icon">
        <div class="icon-box bg-primary">
          <font-awesome-icon class="white" :icon="'fa-solid ' + budget.icon" />
        </div>
      </div>
      <div class="col-detail">
        <div class="primary">
          <font-awesome-icon class="icon-mobile" :icon="'fa-solid ' + budget.icon" /> {{budget.name}}
        </div>
        <div>
          <div>
            <span class="secondary">Remaining: </span>
            <span :class="budget.amount - budget.used_amount>=0? 'primary':'danger'">Rp {{format(budget.amount - budget.used_amount)}},-</span>
          </div>
          <div v-if="budget.reset_type != 'manual'" class="secondary">
            <span>Reset Date: </span>
            <span> {{ formatDate(budget.reset_date)  }} </span>
          </div>
          <div class="secondary">
            <span>Reset Type: </span>
            <span> {{ formatResetType(budget.reset_type) }} </span>
          </div>
          <div class="align-right bold mobile-view">
            <span class="primary">Rp {{format(budget.amount)}},-</span>
          </div>
        </div>
      </div>
      <div class="col-sum align-right bold">
        <span class="primary">Rp {{format(budget.amount)}},-</span>
      </div>
      <div class="col-action" v-if="sort_mode==undefined || sort_mode==false">
        <router-link :to="'/budget/edit/'+budget.id"><button class="bg-primary white"><font-awesome-icon icon="fa-solid fa-edit"/></button></router-link>
      </div>
    </div>
</template>

<script>
import moment from 'moment'

export default {
  props: [
    'budget',
    'sort_mode'
  ],
  methods: {
    format (cost) {
      var numeral = require('numeral')
      return numeral(cost).format('0,0').replaceAll(',', '.')
    },
    formatDate (date) {
      return moment(date).format('D MMM YYYY')
    },
    formatResetType (resetType) {
      return resetType.charAt(0).toUpperCase() + resetType.slice(1)
    }
  }
}
</script>

<style scoped>
  .category-row{
    padding: 10px;
    background-color: white;
    margin-bottom: 5px;
    justify-content: left;
    display: flex;
    align-items: stretch;
    border-radius: 5px;
  }
  .category-row:hover{
    background-color:azure;
  }
  .icon-box{
    width: 50px;
    height: 50px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
  }
  .col-icon{
    width: 58px;
    display: flex;
    align-items: center;
  }
  .col-sum{
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .col-detail{
    width: calc(100% - 58px - 150px - 50px);
  }
  .col-action,.handle{
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .handle{
    width: 25px;
    padding-right: 10px;
  }
  button{
    padding:10px;
    font-size: 15px;
    border: none;
    cursor: pointer;
    margin-left: 5px;
    border-radius: 5px;
  }
  .icon-mobile,.mobile-view{
    display: none;
  }
  @media screen and (max-width: 650px) {
    .col-icon{
      display: none;
    }
    .icon-mobile{
      display: inline;
    }
    .col-sum{
      display: none;
    }
    .col-detail{
      width: calc(100% - 50px);
    }
    .mobile-view{
      display: block;
    }
  }
</style>
