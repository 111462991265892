export default {
  setToken: (state, token) => {
    localStorage.setItem('token', token.key)
    localStorage.setItem('expired_at', token.expiredAt)
    state.token = token
  },
  removeToken: (state) => {
    localStorage.removeItem('token')
    localStorage.removeItem('expired_at')
    state.token = null
  },
  setAlert: (state, payload) => {
    state.alert = {
      isShown: true,
      message: payload.message,
      isError: payload.isError
    }
    setTimeout(function () {
      state.alert.isShown = false
    }, 5000)
  },
  setLoading: (state, progress) => {
    state.process += progress
  },
  setConfirmationPopup: (state, payload) => {
    state.confirmationPopup = payload
  }
}
