export default {
  setToken: ({ commit }, token) => {
    commit('setToken', token)
  },
  removeToken: ({ commit }) => {
    commit('removeToken')
  },
  setSuccessAlert: ({ commit }, message) => {
    commit('setAlert', { message: message, isError: false })
  },
  setErrorAlert: ({ commit }, message) => {
    commit('setAlert', { message: message, isError: true })
  },
  setLoading: ({ commit }) => {
    commit('setLoading', +1)
  },
  finishLoading: ({ commit }) => {
    commit('setLoading', -1)
  },
  setConfirmationPopup: ({ commit }, payload) => {
    console.log(payload)
    commit('setConfirmationPopup', payload)
  }
}
