<template>
  <div>
    <ExpenseDrawer v-if="expenseDrawerVisibility"
      :budgets="budgets"
      :expense="expense"
      @expense-updated="expenseEventHandler"
      @visibility-change="setExpenseDrawerVisibility"
      :mode="expenseDrawerMode"/>
    <Navigation/>
    <div class="panel myriad">
      <div class="title">Filter</div>
      <div class="filter-date">
        <div>
          <div class="label-form">From Date</div>
          <input type="date" v-model="filter.from_date">
        </div>
        <div>
          <div class="label-form">To Date</div>
          <input type="date" :min="filter.from_date" v-model="filter.to_date">
        </div>
      </div>
      <div class="label-form">Budget</div>
      <select v-model="filter.budget_id">
        <option value="">Any</option>
        <option v-for="budget in budgets" :key="budget.id" :value="budget.id">{{budget.name}}</option>
      </select>
      <div class="label-form">Keyword</div>
      <input type="text" v-model="filter.keyword">
      <button class="btn bg-primary" @click="fetchExpenses"><font-awesome-icon icon="fa-solid fa-search"/> Search</button>

      <div class="result-data-header">
        <div class="title">Result</div>
        <div class="sort">
          <DropDownSorter
            :placeholder="'Sort By'"
            :options="sortOptions"
            @option-changed="sortExpense"
          />
        </div>
        <div class="limit" v-show="expenses.length">
          <DropDown
            :placeholder="'Limit'"
            :options="limitOptions"
            @option-changed="setLimit"
          />
        </div>
      </div>
      <ExpenseRows
        v-if="expenses != null"
        :expenses="expenses"
        @edit-expense-selected="showEditExpenseDrawer"/>
      <NoData v-if="(expenses === null || expenses.length<=0) && isExpenseFetched==true"/>
      <div class="paginate myriad primary" v-if="count>filter.limit">
        <span v-for="i in paginate" :key="i" @click="setPage(i)">
          <span :class="i==page ? 'active-page':''">{{i}}</span>
        </span>
      </div>
   </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Navigation from '@/components/Navigation'
import ExpenseRows from '@/components/ExpenseRows'
import ExpenseDrawer from '@/components/ExpenseDrawer'
import NoData from '@/components/NoData'
import moment from 'moment'
import { mapActions } from 'vuex'
import DropDownSorter from '@/components/DropDownSorter'
import DropDown from '@/components/DropDown'
import { fetchBudget } from '@/controllers/budgets'

export default {
  name: 'Report',
  components: {
    Navigation,
    ExpenseDrawer,
    ExpenseRows,
    NoData,
    DropDownSorter,
    DropDown
  },
  data () {
    return {
      expenseDrawerMode: 'UPDATE',
      expenseDrawerVisibility: false,
      isExpenseFetched: false,
      expenses: [],
      budgets: [],
      filter: {
        from_date: moment().format('YYYY-MM') + '-01',
        to_date: moment().format('YYYY-MM-DD'),
        user_id: localStorage.getItem('token'),
        budget_id: '',
        keyword: '',
        limit: 35,
        offset: 0
      },
      count: 0,
      paginate: 0,
      page: 1,
      sortOptions: [
        {
          value: 'date',
          text: 'Date'
        },
        {
          value: 'cost',
          text: 'Cost'
        }
      ],
      limitOptions: [
        {
          value: 35,
          text: '35'
        },
        {
          value: 50,
          text: '50'
        },
        {
          value: 100,
          text: '100'
        },
        {
          value: 150,
          text: '150'
        }
      ]
    }
  },
  watch: {
    'filter.from_date': function (newVal, oldVal) {
      if (this.filter.to_date < newVal) {
        this.filter.to_date = newVal
      }
    }
  },
  methods: {
    ...mapActions([
      'setSuccessAlert',
      'setErrorAlert',
      'setLoading',
      'finishLoading'
    ]),
    setExpenseDrawerVisibility (flag) {
      this.expenseDrawerVisibility = flag
    },
    showEditExpenseDrawer (expense) {
      this.expenseDrawerMode = 'UPDATE'
      this.expense = {
        ...expense
      }
      this.setExpenseDrawerVisibility(true)
    },
    expenseEventHandler (expense) {
      this.fetchCategories()
      this.fetchExpenses()
    },
    setPage (i) {
      this.page = i
      this.filter.offset = this.filter.limit * (i - 1)
      this.fetchExpenses()
    },
    setLimit (limitOption) {
      this.page = 1
      this.filter.offset = 0
      this.filter.limit = limitOption.value
      this.fetchExpenses()
    },
    sortExpense (sortOption) {
      this.filter.sort_by = sortOption.value
      this.filter.sort_order = sortOption.sort_mode
      this.filter.limit = 35
      this.filter.offset = 0
      this.page = 1
      this.fetchExpenses()
    },
    async fetchCategories () {
      this.setLoading()
      try {
        this.budgets = await fetchBudget()
      } catch (err) {
        this.setErrorAlert(err.message)
      }
      this.finishLoading()
    },
    fetchExpenses () {
      if (this.filter.from_date === '') {
        this.setErrorAlert('From Date is required')
        return
      }
      this.setLoading()
      let statusCode = 0

      var query = Object.keys(this.filter).map(key => key + '=' + this.filter[key]).join('&')

      fetch(process.env.VUE_APP_BASE_URL + '/v1/expense?' + query, {
        method: 'get',
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
      }).then((response) => {
        this.isExpenseFetched = true
        statusCode = response.status
        if (statusCode !== 200 && statusCode !== 400 && statusCode !== 401) {
          this.setErrorAlert('Something went wrong, please try again later')
          return
        }
        return response.json()
      }).then((jsonData) => {
        this.finishLoading()
        if (jsonData === undefined) {
          return
        }
        if (statusCode !== 200) {
          this.setErrorAlert(jsonData.message)
          return
        }

        let result = jsonData.expenses
        if (result == null) {
          result = []
        }
        this.expenses = result
        this.count = jsonData.count
        this.paginate = Math.ceil(this.count / this.filter.limit)
      })
    }
  },
  mounted: function () {
    this.fetchCategories()
    this.fetchExpenses()
  }
}

</script>

<style scoped>
  .filter-date,.result-data-header{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .filter-date>div{
    width: 50%;
  }
  .filter-date>div:nth-child(odd){
    margin-right: 10px;
  }

  .result-data-header{
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 20px;
    justify-content: left;
  }
  .result-data-header>.title{
    width: 90%;
    text-align: left;
  }
  .result-data-header>.limit{
    width: 10%;
  }
  .result-data-header>.sort{
    width: 20%;
  }
  .paginate{
    text-align: center;
    padding: 10px;
  }
  .paginate>span{
    cursor: pointer;
    margin-left: 10px;
  }
  .paginate>span:first-child{
    margin-left: 0;
  }
  .active-page{
    text-decoration: underline;
    font-weight: bold;
  }
  @media screen and (max-width: 650px) {
    .result-data-header>.title{
      width: 80%;
    }
    .result-data-header>.limit{
      width: 30%;
    }
    .result-data-header>.sort{
      width: 40%;
    }
  }
</style>
