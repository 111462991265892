<template>
  <div v-if="alert.isShown" :class="alert.isError === true ? 'alert bg-danger' : 'alert bg-primary'">
    <span class="alert-icon">
      <font-awesome-icon v-if="alert.isError == false" icon="fa-solid fa-check"/>
    </span>
    <span class="alert-action">
      <font-awesome-icon v-if="alert.isError == true" icon="fa-solid fa-exclamation-triangle"/>
    </span>
    <span class="alert-message">{{ alert.message }}</span>

    <font-awesome-icon @click="closeAlert" class="float-right pointer" icon="fa-solid fa-times"/>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState([
      'alert'
    ])
  },
  methods: {
    closeAlert () {
      this.alert.isShown = false
    }
  }
}
</script>

<style scoped>
  .alert{
    width: 300px;
    padding: 10px;
    font-family: 'myriad';
    color: white;
    position: fixed;
    bottom: 5%;
    left: 4%;
    z-index: 9999999999;
    display: flex;
    justify-content: left;
    align-items: center;
  }
  .alert>.alert-action {
    display: flex;
    justify-content: left;
    margin-right: 8px;
  }
  .alert>.alert-message{
    width: 88%;
  }
  .fa-times{
    text-align: right;
  }
</style>
