import moment from 'moment'

export default {
  isAuthenticated: (state) => {
    if (state.token === null || state.token.key === null) {
      return false
    }

    const now = moment().utc().format('YYYY-MM-DD HH:mm:ss')
    if (now >= state.token.expiredAt) {
      return false
    }

    return true
  }
}
