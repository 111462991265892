<template>
<div>
    <div v-if="budgets != null && budgets!=undefined && budgets.length>0" class="category-area">
        <div class="category-column" v-for="(budgets, i) in groupedData" :key="i">
            <div class="category-card pointer" v-for="budget in budgets" :key="budget.id">
              <div @click="() => redirectToEditPage(budget.id)">
                <div class="category-name">
                    <font-awesome-icon class="primary" :icon="budget.icon"/>  <b>{{ budget.name }}</b>
                </div>
                <!-- category detail -->
                <div class="category-detail">
                    <div>
                        <span class="secondary">Budget </span>
                        <span class="primary">Rp {{ format(budget.amount) }},-</span>
                    </div>
                    <div>
                        <span class="secondary">Used </span>
                        <span class="primary">Rp {{ format(budget.used_amount) }},-</span>
                    </div>
                </div>
                <!-- category summary -->
                <div :class="budget.amount - budget.used_amount>=0?'primary summary':'danger summary'">Rp {{ format(budget.amount - budget.used_amount)}},-</div>
              </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
  props: ['budgets'],
  methods: {
    format (cost) {
      var numeral = require('numeral')
      return numeral(cost).format('0,0').replaceAll(',', '.')
    },
    redirectToEditPage (id) {
      this.$router.push('/budget/edit/' + id)
    }
  },
  computed: {
    groupedData () {
      const result = []
      const screenWidth = window.innerWidth

      // one rows
      if (this.budgets.length <= 2 || (this.budgets.length <= 3 && screenWidth > 425)) {
        for (let i = 0; i < this.budgets.length; i += 1) {
          result.push([this.budgets[i]])
        }
        return result
      }

      // split into two rows
      for (let i = 0; i < this.budgets.length; i += 2) {
        if (i === this.budgets.length - 1) {
          result.push([this.budgets[i]])
        } else {
          result.push([this.budgets[i], this.budgets[i + 1]])
        }
      }
      return result
    }
  }
}
</script>

<style scoped>

.category-area {
    max-height: 260px;
    padding-bottom: 12px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
}

.category-column {
    vertical-align: top;
    display: inline-block;
    margin-right: 8px;
}

.category-card {
    width: 250px;
    padding: 15px;
    border: 1px solid lightgray;
    border-radius: 5px;
    background-color: white;
}

.category-card:hover {
  background-color: azure;
}

.category-card:nth-child(even) {
    margin-top: 8px;
}

.category-name {
    padding-bottom: 10px;
}

.category-detail {
    font-size: 14px;
}

.summary {
    margin-top: 10px;
    text-align: right;
    font-weight: bold;
}

@media screen and (max-width: 650px) {
    .category-card{
        width: 150px;
        font-size: 14px;
    }
    .category-detail{
        font-size: 13px;
    }
    .category-name{
        padding-bottom: 5px;
    }
}
</style>
