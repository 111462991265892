<template>
    <div>
      <GoogleLogin class="full-btn bg-primary white" :params="params" :onSuccess="signIn"><font-awesome-icon icon="fa-brands fa-google"/> Sign In</GoogleLogin>
    </div>
</template>

<script>
import GoogleLogin from 'vue-google-login'

export default {
  props: {
    signIn: Function
  },
  data () {
    return {
      params: {
        client_id: '86037008978-22fmhue27qpn4mhi1of5pra4g0s2a7ob.apps.googleusercontent.com'
      }
    }
  },
  components: {
    GoogleLogin
  }
}
</script>

<style scoped>
</style>
