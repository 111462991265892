import { InternalServerError } from '../errors/internal-server-error'
import { getJSONResponse } from './response-extractor'

export async function fetchBudget () {
  let response
  try {
    response = await fetch(process.env.VUE_APP_BASE_URL + '/v1/budget', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + localStorage.getItem('token') }
    })
  } catch (err) {
    throw InternalServerError
  }

  const jsonResponse = await getJSONResponse(response)

  return jsonResponse.budgets
}

export async function fetchBudgetById (id) {
  let response
  try {
    response = await fetch(process.env.VUE_APP_BASE_URL + '/v1/budget/' + id, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + localStorage.getItem('token') }
    })
  } catch (err) {
    throw InternalServerError
  }

  const jsonResponse = await getJSONResponse(response)

  return jsonResponse
}
