<template>
  <div class="custom-combobox" @click="toggleOptions">
    <div class="selected-item">
      {{ selectedOption.text }}
      <span class="float-right">
        <font-awesome-icon icon="fa-caret-down"/>
      </span>
    </div>
    <ul class="options" :class="showOptions === true ? 'active' : ''">
      <li v-show="placeholder" class="option-title" @click.stop>{{placeholder}}</li>
      <li
        class="option"
        v-for="(option, index) in options"
        :key="index"
        @click="selectOption(option)"
        @click.stop
      >
        <div :class="selectedOption.value === option.value ? 'primary' : ''">
          {{ option.text }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      required: false
    },
    options: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      showOptions: false,
      selectedOption: this.options[0]
    }
  },
  methods: {
    toggleOptions () {
      this.showOptions = !this.showOptions
    },
    selectOption (option) {
      if (option.value === this.selectedOption.value) {
        return
      }
      this.selectedOption = option
      this.showOptions = false

      this.$emit('option-changed', {
        value: this.selectedOption.value
      })
    },
    closeOptionsOnOutsideClick (event) {
      if (!this.$el.contains(event.target)) {
        this.showOptions = false
      }
    }
  },
  mounted () {
    document.addEventListener('click', this.closeOptionsOnOutsideClick)
  },
  beforeDestroy () {
    document.removeEventListener('click', this.closeOptionsOnOutsideClick)
  }
}
</script>

<style scoped>
.custom-combobox {
  position: relative;
  width: 100%;
  cursor: pointer;
  font-size: 12px;
}

.selected-item {
  padding: 8px;
}

.options {
  list-style: none;
  margin: 0;
  padding: 0;
  border: 1px solid #ccc;
  display: none;
  position: absolute;
  width: 100%;
  background-color: white;
  z-index: 1;
}

.option, .option-title {
  padding: 8px;
}

.option-title {
  font-weight: bold;
  color: gray;
  font-size: 11px;
}

.options.active {
  display: block;
}
</style>
