<template>
  <div>
    <div class="label">{{ label }} <span v-if="mandatory" class="danger">*</span></div>
    <input class="date-field" type="date" :min="min" :value="value" :placeholder="placeholder" @input="updateModelValue($event.target.value)">
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: false
    },
    mandatory: {
      type: Boolean,
      required: false
    },
    min: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false
    }
  },
  methods: {
    updateModelValue (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style>
  .label{
    font-size: 14px;
    font-family: 'myriad';
    margin-bottom: 5px;
  }
  .date-field{
    border:1px solid lightgray;
    border-radius:5px;
    padding: 10px;
    font-family: myriad;
    margin-bottom: 8px;
    width: calc(100% - 22px);
  }
</style>
