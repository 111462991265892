<template>
  <div class="icon-button bg-primary white">
    <font-awesome-icon :icon="'fa-solid '+icon"/> {{ text }}
  </div>
</template>

<style scoped>
.icon-button{
  border-radius: 5px;
  text-decoration: none !important;
  text-align: center !important;
  padding: 8px;
  font-size: 12px;
}
</style>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    }
  }
}
</script>
