export default {
  process: 0,
  token: {
    key: localStorage.getItem('token'),
    expiredAt: localStorage.getItem('expired_at')
  },
  alert: {
    isShown: false,
    message: '',
    isError: false
  },
  confirmationPopup: {
    isShown: false,
    content: '',
    title: '',
    confirmHandler: null
  }
}
