<template>
  <div id="app">
      <ConfirmationPopup/>
      <Loading/>
      <Alert/>
    <router-view/>
  </div>
</template>

<script>
import Loading from '@/components/Loading.vue'
import ConfirmationPopup from '@/components/ConfirmationPopup.vue'
import Alert from '@/components/Alert.vue'

export default {
  components: {
    Loading,
    ConfirmationPopup,
    Alert
  }
}
</script>

<style>
    .full-btn{
        width: 100%;
        font-size: 15px;
        font-weight: bold;
    }
    body {
        margin: 0;
        padding: 0;
        padding-bottom: 50px;
        background-color: #f2f2f2;
    }
    .dim{
        width: 100vw;
        height: 100vh;
        background: rgba(0.5, 0.5, 0.5, 0.7);
        position: fixed !important;
        z-index: 1;
    }
    @font-face {
        font-family: myriad;
        src: url('../src/assets/myriadpro.ttf');
    }
    @font-face{
        font-family: montserrat;
        src:url('../src/assets/montserrat.ttf');
    }
    ::-webkit-scrollbar {
        width: 5px;
        height: 8px;
        background-color: lightgray;
    }
    ::-webkit-scrollbar-thumb {
        background: #22b573;
        border-radius: 100px;
        border:1px solid lightseagreen;
    }

    ::-moz-scrollbar {
        width: 5px;
        height: 8px;
        background-color: lightgray;
    }

    ::-moz-scrollbar-thumb {
        background: #22b573;
        border-radius: 100px;
        border:1px solid lightseagreen;
    }

    a{
        cursor: pointer;
    }

    .danger{
        color: #DB4437;
    }

    .primary{
        color: #009688 !important;
    }

    .primary-hover:hover{
        color: #2196f3 !important;
    }

    .inline{
        display: inline-block;
    }

    .fixed{
        position: fixed;
    }

    .absolute{
        position: absolute;
    }

    .black{
        color: black;
    }

    .white{
        color: white;
    }

    .secondary{
        color: gray;
    }

    .secondary-hover:hover{
        background-color: lightgray;
    }

    .bold{
        font-weight: bold;
    }

    .bg-primary{
        background-color:#009688;
    }

    .border-primary {
        border: 1px solid #009688 !important;
    }

    .border-white {
        border: 1px solid white !important;
    }

    .bg-white{
        background-color: white;
    }

    .bg-danger{
        background-color: #DB4437;
    }

    .align-center{
        text-align: center;
    }

    .align-right{
        text-align: right;
    }

    .align-left{
        text-align: left;
    }

    .float-right{
        float: right;
    }

    .float-left{
        float: left;
    }

    .pointer{
        cursor: pointer;
    }

    .myriad{
        font-family: 'myriad';
    }

    .title {
        font-weight: bold;
        font-family: "myriad";
        color: #009688 !important;
        margin-bottom: 8px;
    }

    .panel {
        margin: auto;
        margin-top: 15px;
        width: 70%;
        border-radius: 15px;
    }

    input:not(.checkbox),select,textarea{
        border:1px solid lightgray;
        border-radius:5px;
        padding: 10px;
        font-family: myriad;
        margin-bottom: 8px;
        width: calc(100% - 22px);
    }

    textarea{
        max-width: calc(100% - 22px);
        min-height: 100px;
        resize: none;
    }

    .label-form{
        font-size: 14px;
        font-family: 'myriad';
        margin-bottom: 5px;
    }

    select{
        width: 100%;
    }

    option{
        padding: 8px;
    }
    input:active,input:focus,select:focus,textarea:focus{
        border: 1px solid #009688 !important;
        outline: none;
    }
    .btn,.full-btn{
        padding: 10px;
        border-radius: 5px;
        color: white;
        border:none;
        outline: none;
        cursor: pointer;
        outline: none;
    }
    .row{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    @media screen and (max-width: 650px) {
        .panel{
            width: 90%;
        }
    }
    @media screen and (min-width: 1367px) {
        .panel{
            width: 50%;
        }
    }
    @media screen and (min-width: 2561px) {
        .panel{
            width: 40%;
        }
    }
</style>
