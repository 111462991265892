<template>
  <div>
    <div v-for="expense in expenses" :key="expense.id" class="expense-wrapper">
      <div @click="setSelectedId(expense.id)" class="expense myriad">
        <div class="category-icon-desktop bg-primary white expense-icon">
          <font-awesome-icon :icon="'fa-solid ' + (expense.icon ?? 'fa-question')"/>
        </div>
        <div class="content">
          <div class="secondary" v-show="showDate === true">{{formatDate(expense.date)}}</div>
          <div>{{expense.name}}</div>
          <div class="danger" v-if="expense.notes !== ''">{{expense.notes}}</div>
          <div class="secondary">
            <font-awesome-icon class="category-icon-mobile primary" :icon="'fa-solid ' + (expense.icon ?? 'fa-question')"/> {{expense.budget_name ?? 'Unknown Budget'}}
          </div>
        </div>
        <div class="align-right bold cost-panel">Rp {{format(expense.cost)}},-</div>
      </div>
      <div v-if="expense.id==selectedId" class="expense-action">
        <span @click="editExpense(expense)"><button class="btn bg-primary"><font-awesome-icon icon="fa-solid fa-edit"/> Edit</button></span>
        <button class="btn bg-danger" @click="showDeleteExpenseConfirmation(expense.id)"><font-awesome-icon icon="fa-solid fa-trash"/> Remove</button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'

export default {
  props: {
    expenses: {
      type: Array,
      required: true
    },
    showDate: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  components: {
  },
  data () {
    return {
      selectedId: ''
    }
  },
  methods: {
    ...mapActions([
      'setSuccessAlert',
      'setErrorAlert',
      'setLoading',
      'finishLoading',
      'setConfirmationPopup'
    ]),
    showDeleteExpenseConfirmation (id) {
      this.setConfirmationPopup({
        isShown: true,
        title: 'Delete Expense',
        content: 'Are you sure want to delete this expense?',
        confirmHandler: () => this.deleteExpense(id)
      })
    },
    removeExpenseFromRows (id) {
      for (var i = 0; i < this.expenses.length; i++) {
        if (this.expenses[i].id === id) {
          this.expenses.splice(i, 1)
          return
        }
      }
    },
    deleteExpense (id) {
      this.setLoading()
      let statusCode = 0

      fetch(process.env.VUE_APP_BASE_URL + '/v1/expense', {
        method: 'delete',
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + localStorage.getItem('token') },
        body: JSON.stringify({ id: id })
      }).then((response) => {
        statusCode = response.status
        if (statusCode !== 200 && statusCode !== 400 && statusCode !== 401) {
          this.setErrorAlert('Something went wrong, please try again later')
          return
        }
        return response.json()
      }).then((jsonData) => {
        this.finishLoading()
        if (jsonData === undefined) {
          return
        }
        if (statusCode !== 200) {
          this.setErrorAlert(jsonData.message)
          return
        }
        this.removeExpenseFromRows(id)
        this.setSuccessAlert('Success')
      })
    },
    format (cost) {
      var numeral = require('numeral')
      return numeral(cost).format('0,0').replaceAll(',', '.')
    },
    formatDate (date) {
      return moment(date).format('dddd, D MMM YYYY')
    },
    setSelectedId (id) {
      if (id === this.selectedId) {
        this.selectedId = ''
        return
      }
      this.selectedId = id
    },
    editExpense (expense) {
      this.$emit('edit-expense-selected', expense)
    }
  }
}
</script>

<style scoped>
  .total{
    padding: 10px;
    margin-bottom: 7px;
  }
  .btn{
    min-width: 50px;
    margin-left: 8px;
  }
  .expense{
    display: flex;
    align-items: center;
    padding: 8px;
    justify-content: center;
    cursor: pointer;
  }
  .expense>div{
    width: 50%;
  }
  .expense-wrapper:hover{
    background-color: azure;
  }
  .expense-wrapper{
    margin-bottom: 5px;
    background-color: white;
    border-radius: 5px;
  }
  .expense-action{
    text-align: right;
    padding: 8px;
  }
  .expense-icon{
    width: 50px !important;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    font-size: 20px;
    border-radius: 5px;
  }
  .category-icon-mobile{
    display: none;
  }
  .category-icon-desktop{
    width: 50px !important;
  }
  .content{
    width: calc(100% - 200px) !important;
  }
  .cost-panel{
    width: 150px !important;
  }
  @media screen and (max-width: 650px) {
    .category-icon-mobile{
      display: inline-block;
    }
    .category-icon-desktop{
      display: none;
    }
    .expense{
      font-size: 14px;
    }
    .cost-panel{
      width: 100px;
    }
    .content{
      width: calc(100% - 100px) !important;
    }
  }
</style>
