<template>
  <div>
    <Navigation/>
    <div class="panel expense-panel">
      <div class="title">
        <div>
          {{mode}} Budget
        </div>
        <div class="align-right" v-if="mode==='Edit'">
          <button @click="showRemoveConfirmation(budget.id)" class="btn bg-danger white">
            <font-awesome-icon icon="fa-solid fa-trash"/> Delete
          </button>
        </div>
      </div>

      <TextField label="Name" :mandatory="true" v-model="budget.name" />
      <MoneyField label="Budget" :mandatory="true" v-model.number="budget.amount"/>
      <DropDownField label="Reset Type" :mandatory="true" v-model="budget.reset_type" :options="resetTypes" textName="name" placeholder="-- Select Reset Type --"/>
      <DateField label="Reset Date" v-if="budget.reset_type !== 'manual'" :min="minResetDate" :mandatory="true" v-model="budget.reset_date"/>

      <div class="label-form">Icon <span class="danger">*</span></div>
      <div class="icon-wrapper align-center">
        <button v-for="icon in icons"
          :class="budget.icon===icon? 'icon-button active-icon':'icon-button'"
          :key="icon"
          @click="setIcon(icon)"
          >
          <font-awesome-icon :icon="icon"/>
        </button>
      </div>
      <button class="btn bg-primary" @click="this.submit">{{mode === "Edit" ? "Save" : mode}}</button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Navigation from '@/components/Navigation'
import DateField from '../components/DateField'
import MoneyField from '@/components/MoneyField'
import TextField from '@/components/TextField'
import DropDownField from '@/components/DropDownField'
import { mapActions } from 'vuex'
import moment from 'moment'
import { fetchBudgetById } from '@/controllers/budgets'

export default {
  data () {
    return {
      minResetDate: moment().add(1, 'days').format('YYYY-MM-DD'),
      mode: 'Create',
      budget: {
        id: '',
        name: '',
        icon: '',
        amount: 0,
        reset_date: '',
        reset_type: 'monthly'
      },
      resetTypes: [
        {
          id: 'monthly',
          name: 'Monthly'
        },
        {
          id: 'manual',
          name: 'Manual'
        }
      ],
      icons: [
        'fa-utensils',
        'fa-car',
        'fa-shopping-bag',
        'fa-truck-fast',
        'fa-bolt',
        'fa-tint',
        'fa-stethoscope',
        'fa-signal',
        'fa-wifi',
        'fa-heart',
        'fa-home',
        'fa-bed',
        'fa-birthday-cake',
        'fa-basketball-ball',
        'fa-bicycle',
        'fa-graduation-cap',
        'fa-university',
        'fa-school',
        'fa-money-check-alt',
        'fa-piggy-bank',
        'fa-grin-squint-tears',
        'fa-film',
        'fa-gift',
        'fa-dumbbell',
        'fa-plane',
        'fa-users',
        'fa-mug-hot'
      ]
    }
  },
  components: {
    Navigation,
    DateField,
    TextField,
    MoneyField,
    DropDownField
  },
  methods: {
    ...mapActions([
      'setSuccessAlert',
      'setErrorAlert',
      'setLoading',
      'finishLoading',
      'setConfirmationPopup'
    ]),
    setIcon (icon) {
      this.budget.icon = icon
    },
    format (cost) {
      var numeral = require('numeral')
      return numeral(cost).format('0,0').replaceAll(',', '.')
    },
    addBudget () {
      this.setLoading()
      let statusCode = 0

      fetch(process.env.VUE_APP_BASE_URL + '/v1/budget', {
        method: 'post',
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + localStorage.getItem('token') },
        body: JSON.stringify(this.budget)
      }).then((response) => {
        statusCode = response.status
        if (statusCode !== 200 && statusCode !== 201 && statusCode !== 400 && statusCode !== 401) {
          this.setErrorAlert('Something went wrong, please try again later')
          return
        }
        return response.json()
      }).then((jsonData) => {
        this.finishLoading()
        if (jsonData === undefined) {
          return
        }
        if (statusCode !== 200 && statusCode !== 201) {
          this.setErrorAlert(jsonData.message)
          return
        }
        this.budget = {
          id: '',
          name: '',
          icon: '',
          budget: 0,
          reset_type: 'monthly'
        }
        this.setSuccessAlert('Success!')
        this.budget = {
          id: '',
          name: '',
          icon: '',
          amount: 0,
          reset_date: '',
          reset_type: 'monthly'
        }
      })
    },
    editBudget () {
      this.setLoading()
      let statusCode = 0

      fetch(process.env.VUE_APP_BASE_URL + '/v1/budget', {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + localStorage.getItem('token') },
        body: JSON.stringify(this.budget)
      }).then((response) => {
        statusCode = response.status
        if (statusCode !== 200 && statusCode !== 400 && statusCode !== 401) {
          this.setErrorAlert('Something went wrong, please try again later')
          return
        }
        return response.json()
      }).then((jsonData) => {
        this.finishLoading()
        if (jsonData === undefined) {
          return
        }
        if (statusCode !== 200) {
          this.setErrorAlert(jsonData.message)
          return
        }

        this.setSuccessAlert('Success!')
        this.$router.back()
      })
    },
    submit () {
      if (this.budget.name === '') {
        this.setErrorAlert('Budget Name is required')
        return
      } else if (this.budget.budget < 0) {
        this.setErrorAlert('Monthly Budget is required')
        return
      } else if (this.budget.icon === '') {
        this.setErrorAlert('Budget Icon is required')
        return
      } else if (this.budget.reset_type === '') {
        this.setErrorAlert('Reset Type is required')
      } else if (this.budget.reset_date === '' && this.budget.reset_type !== 'manual') {
        this.setErrorAlert('Reset Date is required')
        return
      } else if (this.budget.reset_type !== 'manual' && this.budget.reset_date <= moment().format('YYYY-MM-DD')) {
        this.setErrorAlert('Reset Date should be greater than today')
        return
      }

      if (this.mode === 'Create') {
        this.addBudget()
      } else if (this.mode === 'Edit') {
        this.editBudget()
      }
    },
    async fetchBudget () {
      this.setLoading()
      try {
        this.budget = await fetchBudgetById(this.budget.id)
      } catch (err) {
        this.setErrorAlert(err.message)
      }
      this.finishLoading()
    },
    showRemoveConfirmation (id) {
      this.setConfirmationPopup({
        isShown: true,
        title: 'Delete Budget',
        content: 'Are you sure want to delete this budget?',
        confirmHandler: () => this.removeBudget(id)
      })
    },
    removeBudget (id) {
      this.setLoading()
      let statusCode = 0

      fetch(process.env.VUE_APP_BASE_URL + '/v1/budget', {
        method: 'delete',
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + localStorage.getItem('token') },
        body: JSON.stringify({ id: id })
      }).then((response) => {
        statusCode = response.status
        if (statusCode !== 200 && statusCode !== 400 && statusCode !== 401) {
          this.setErrorAlert('Something went wrong, please try again later')
          return
        }
        return response.json()
      }).then((jsonData) => {
        this.finishLoading()
        if (jsonData === undefined) {
          return
        }
        if (statusCode !== 200) {
          this.setErrorAlert(jsonData.message)
          return
        }
        this.setSuccessAlert('Success')
        this.$router.back()
      })
    }
  },
  mounted: function () {
    if (this.$route.params.id !== undefined) {
      this.mode = 'Edit'
      this.budget.id = this.$route.params.id
      this.fetchBudget()
    }
  },
  watch: {
    'budget.reset_type': {
      handler (newResetType, oldResetType) {
        if (newResetType === 'manual') {
          this.budget.reset_date = ''
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>
  .expense-panel{
    width: 500px !important;
  }
  .icon-wrapper{
    margin-bottom: 15px;
  }
  .icon-button{
    border: none;
    color: #009688;
    background-color: white;
    padding: 12px;
    font-size: 18px;
    margin-right: 5px;
    margin-bottom: 5px;
    width: 50px;
    height: 50px;
    outline:none;
  }
  .active-icon{
    background-color: #009688;
    color: white;
  }
  .icon-button:hover{
    background-color: #009688;
    color:white
  }
  .title{
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 15px;
  }
  .title>div{
    width:50%;
  }
  @media screen and (max-width: 650px) {
    .expense-panel{
      width: 90% !important;
    }
  }
</style>
