<template>
  <div>
    <div class="label">{{ label }} <span v-if="mandatory" class="danger">*</span></div>
    <input
      class="money-field"
      type="text"
      :value="formattedCost"
      v-input-number-format
      @input="updateModelValue($event.target.value)"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: true
    },
    mandatory: {
      type: Boolean,
      required: false
    },
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      formattedCost: this.formatNumber(this.value)
    }
  },
  directives: {
    inputNumberFormat: {
      bind (el, binding, vnode) {
        el.addEventListener('input', (event) => {
          const value = event.target.value.replace(/\D/g, '') // Remove non-numeric characters
          const formattedValue = vnode.context.formatNumber(Number(value)) // Convert to formatted string with custom formatting
          event.target.value = formattedValue
          vnode.context.formattedCost = formattedValue // Update the formatted value in the Vue component
          vnode.context.$emit('input', value) // Emit input event to update the parent's v-model
        })
      }
    }
  },
  methods: {
    updateModelValue (formattedValue) {
      const value = Number(formattedValue.replace(/\D/g, '')) // Remove non-numeric characters and convert to a number
      this.formattedCost = this.formatNumber(value) // Update the formatted value in the component
      this.$emit('input', value) // Emit input event to update the parent's v-model
    },
    formatNumber (value) {
      if (value === 0) {
        return ''
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    }
  },
  watch: {
    value (newValue) {
      if (newValue === 0) {
        this.formattedCost = '' // Set the formatted value to an empty string when the value is undefined
        return
      }
      this.formattedCost = this.formatNumber(newValue) // Update the formatted value when the value prop changes
    }
  }
}
</script>

<style>
  .label{
    font-size: 14px;
    font-family: 'myriad';
    margin-bottom: 5px;
  }
  .money-field{
    border:1px solid lightgray;
    border-radius:5px;
    padding: 10px;
    font-family: myriad;
    margin-bottom: 8px;
    width: calc(100% - 22px);
  }
</style>
