<template>
  <div v-if="process > 0" class="dim loading-dim">
    <div class="loading-indicator"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState([
      'process'
    ])
  }
}
</script>

<style scoped>
  .loading-dim{
    z-index: 99999 !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .loading-indicator{
    width: 50px;
    height: 50px;
    border-radius: 100%;
    border: 5px solid transparent;
    border-right: 5px solid #009688;
    border-bottom: 5px solid #009688;
    border-top: 5px solid gray;
    border-left: 5px solid gray;
    animation:spin 0.5s linear infinite;
  }
  @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
</style>
