import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { LoaderPlugin } from 'vue-google-login'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { faGoogle, faYoutube, faSpotify } from '@fortawesome/free-brands-svg-icons'
import {
  faCaretDown, faArrowUp, faArrowDown, faSearch, faExclamationTriangle, faCheck,
  faTimes, faPlus, faCalendarAlt, faSignOutAlt, faCreditCard, faCar, faShoppingBag,
  faBolt, faHome, faSignal, faBed, faBirthdayCake, faBasketballBall, faBicycle,
  faGraduationCap, faUniversity, faSchool, faMoneyCheckAlt, faTint, faPiggyBank,
  faGrinSquintTears, faUser, faUtensils, faHeart, faBars, faEdit, faTrash, faQuestion,
  faStethoscope, faTruckFast, faFilm, faGift, faDumbbell, faPlane, faUsers, faMugHot,
  faWifi
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faCaretDown, faArrowUp, faArrowDown, faGoogle, faSearch, faExclamationTriangle, faCheck, faTimes, faPlus,
  faSignOutAlt, faCreditCard, faCalendarAlt, faCar, faShoppingBag, faBolt, faHome, faSignal, faBed, faBirthdayCake,
  faBasketballBall, faBicycle, faGraduationCap, faUniversity, faSchool, faMoneyCheckAlt, faTint, faPiggyBank, faGrinSquintTears,
  faUser, faUtensils, faHeart, faBars, faEdit, faTrash, faQuestion, faStethoscope, faTruckFast, faFilm, faGift, faDumbbell,
  faPlane, faYoutube, faSpotify, faUsers, faMugHot, faWifi
)

Vue.component('font-awesome-icon', FontAwesomeIcon)

// Make sure to allow expenhub to access google sign in form here: https://console.cloud.google.com/apis/credentials > OAuth Client ID

Vue.use(LoaderPlugin, {
  client_id: '86037008978-22fmhue27qpn4mhi1of5pra4g0s2a7ob.apps.googleusercontent.com'
})
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
