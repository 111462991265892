<template>
  <div class="panel">
    <div class="card bg-primary white">
      <div class="profile">
        <div class="bg-white icon-box">
          <font-awesome-icon class="primary" icon="fa-solid fa-user" />
        </div>
        <div>{{name}}</div>
      </div>
      <div class="remaining-label">Total Remaining Budget</div>
      <div class="remaining bold">Rp {{remaining_budget}},-</div>
      <div class="budget"><span>Total Budget:</span> Rp {{budget}},-</div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'name',
    'budget',
    'remaining_budget'
  ]
}
</script>

<style scoped>
  .panel{
    border-radius: 0;
    margin-top: 15px;
  }
  .card{
    border-radius: 5px;
    padding: 12px;
  }
  .remaining-label{
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .remaining{
    font-size: 18px;
    margin-bottom: 8px;
  }
  .budget{
    font-size: 13px;
  }
  .profile{
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  .icon-box{
    width: 20px;
    height: 20px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    border-radius: 100px;
    margin-right: 5px;
  }
  @media screen and (max-width: 650px) {
    .panel{
      width: 100%;
      margin-top: 0;
    }
    .card{
      border-radius: 0;
    }
  }
</style>
