import { InternalServerError } from '../errors/internal-server-error'

export async function getJSONResponse (response) {
  const statusCode = response.status
  if (statusCode !== 200 && statusCode !== 201 && statusCode !== 400 && statusCode !== 401) {
    throw InternalServerError
  }

  const jsonResponse = await response.json()

  if (statusCode !== 200 && statusCode !== 201) {
    throw new Error(jsonResponse.message)
  }

  return jsonResponse
}
